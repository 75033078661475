// eslint-disable-next-line unicode-bom
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dropdown, message, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from "react-redux";
import { Link, useHistory, withRouter } from 'react-router-dom';
import { T } from '../../components/Translations';
import { ApiUrl, AppPages, Defines, UserRoles } from '../../project/Defines';
import { Project } from '../../project/Project';
import { EVENT_NAME, useCustomEvent } from '../../project/utilities';
import { hideLoader, showLoader } from '../../Shared/Loader';
import Scroll from '../../Shared/Scroll/Scroll';
import { changeCulture, clearListStates, closeAllPopups, login, openPopup } from '../../store/actions';
import { Ajax } from '../Ajax';
import { PopupClasses, PopupTypes } from '../Popup';

import moment from 'moment';

const LanguageChange = (props) => {

  let { culture } = props;
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const ref = useRef(false);

  useEffect(() => {
    ref.current = true;

    let language = i18n.language;
    if (language.indexOf("-") !== -1) {
      language = language.split('-')[0];
    }
    dispatch(changeCulture(culture || language));
    return () => { ref.current = false; };

  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    i18n.changeLanguage(culture);
  }, [i18n, culture]);

  const changeLanguage = lng => {
    if (ref.current) {
      //i18n.changeLanguage(lng);
      dispatch(changeCulture(lng));
    }
  };

  return <>
    <Tooltip placement="bottom" title={t('languages.es')}>
      <div effect="material" className={culture === 'es' ? "button primary" : "button"} onClick={() => changeLanguage('es')}><text>es</text></div>
    </Tooltip>
    <Tooltip placement="bottom" title={t('languages.pt')}>
      <div effect="material" className={culture === 'pt' ? "button primary" : "button"} onClick={() => changeLanguage('pt')}><text>pt</text></div>
    </Tooltip>
    <Tooltip placement="bottom" title={t('languages.en')}>
      <div effect="material" className={culture === 'en' ? "button primary" : "button"} onClick={() => changeLanguage('en')}><text>en</text></div>
    </Tooltip>
    <Tooltip placement="bottom" title={t('languages.fr')}>
      <div effect="material" className={culture === 'fr' ? "button primary" : "button"} onClick={() => changeLanguage('fr')}><text>fr</text></div>
    </Tooltip>
    <Tooltip placement="bottom" title={t('languages.it')}>
      <div effect="material" className={culture === 'it' ? "button primary" : "button"} onClick={() => changeLanguage('it')}><text>it</text></div>
    </Tooltip>
  </>;
};
/**
 * represents the control for selecting current interface language */
const LanguageChangeComponent = connect(state => ({
  culture: state.culture
}), null)(LanguageChange);

export const menuItems = [
  { to: AppPages.Planning, text: <T>text.planning</T> },
  { to: AppPages.ServiceCalls, text: <T>text.service_calls</T>, detail: AppPages.ServiceCallsDetails },
  {
    to: AppPages.ServiceCallsUnassigned, text: <T>text.service_calls_unassigned_declined</T>,
    render: (unassigned, selected) => <>
      <text><T>text.service_calls_unassigned_declined</T></text>
      {unassigned > 0 && <div command="notification" effect="material" className="button special micro" data-tip="" style={{ padding: '3px', width: 'auto', minWidth: '20px', height: '20px', marginBottom: '10px', backgroundColor: selected ? '#fff' : 'var(--main-color)', color: selected ? '#000' : '#fff' }}>{unassigned}</div>}
    </>
  },
  {
    text: <T>text.documents</T>, subMenu: [
      { to: AppPages.Fees, text: <T>text.fees</T>, selected: false },
      { to: AppPages.SpareParts, text: <T>text.spare_parts</T>, selected: false },
      { to: AppPages.DPC, text: <T>text.dpc</T>, selected: false }
    ]
  },
  { to: AppPages.InvoiceGeneration, text: <T>text.invoice_generation</T> },
  {
    to: AppPages.ServiceContract, text: <T>text.service_contract</T>,  detail: AppPages.ServiceContractDetails,
  }
];

/**
 * The layout component specifies the site layout, top menu based on login state and active states of top menu items
 * @param {object} props  { isLoggedIn, popups, culture, role, etc.}
 */

function Menu(props) {
  const { role, closeAllPopups, login, clearListStates, userData, culture, openPopup, isLoggedIn, enableServiceContract, dataMaintenance } = props;
  const [unassigned, setUnassigned] = useState(0);
  const isDispatcher = role === UserRoles.DISPATCHER;
  const location = window.location.pathname.toLowerCase();
  const history = useHistory();
  const [closeMaintenance, setCloseMaintenance] = useState(true);

  let logoutUrl = props.userData && props.userData.logoutUrl ? props.userData.logoutUrl : Project.getPageUrl(AppPages.Logout);
  
  useEffect(() => {
    isDispatcher && updateUnassigned();
  }, [location, isLoggedIn]);//eslint-disable-line react-hooks/exhaustive-deps

  const updateUnassigned = useCallback(() => {
    isDispatcher && Ajax.get({
      url: ApiUrl.GetUnassignedServicaCallsCount,
      data: {},
      success: function (response) {
        if (!response.HasError) {
          setUnassigned(response);
          //  hideLoader(serviceCallLoader);
        } else {
          // hideLoader(serviceCallLoader);
        }
      }
    });
  }, [isDispatcher]);
  useCustomEvent(EVENT_NAME.CALL_ASSIGNED, updateUnassigned);

  const openConnectAs = () => {
    openPopup({
      windowKey: 'wndConnnectAs',
      title: <T>text.connect_as</T>,
      type: PopupTypes.ConnectAsPicker,
      className: PopupClasses.Large,
      fullScreen: false
    });
  };
  const revertConnectAs = () => {
    const connectAsLoader = 'CONNECT_AS_LOADER';
    showLoader(connectAsLoader);
    Ajax.post({
      url: ApiUrl.Logout,
      data: { reverse: true },
      success: function (response) {
        if (response && ((response.status && response.status === 200) || response.redirectUrl)) {
          const redirectUrl = response.redirectUrl;
          closeAllPopups();
          clearListStates();
          login({
            userToken: response.userToken,
            userName: response.userName,
            culture: response.culture,
            role: response.role,
            enableServiceContract: response.enableServiceContract,
            userData: {
              userName: response.userName,
              canCreateCall: response.canCreateCall
            }
          });
          if (redirectUrl && redirectUrl !== Project.getPageUrl(AppPages.Login)) {
            history.push(redirectUrl);
          }
          else {
            history.push(Project.getPageUrl(AppPages.Home));
          }
        } else {
          if (response) {
            message.error(response.title || <T>message.error_api</T>);
          } else {
            message.error(<T>message.error_api</T>);
          }
        }
        hideLoader(connectAsLoader);
      },
      error: function () { hideLoader(connectAsLoader); }
    });
  };
  let userMenu =
    <div className="list_menu ant-dropdown-menu">
      <ul>
        <li command="user"><a><icon></icon><text><T>text.my_account</T></text></a></li> {/*eslint-disable-line jsx-a11y/anchor-is-valid*/}
        {
          role === UserRoles.DISPATCHER &&
          <li onClick={openConnectAs}><icon></icon><text><T>text.connect_as</T></text></li>
        }
        {
          userData && <> {userData.internalConnectAs ?
            <li onClick={revertConnectAs}><icon></icon><text><T>text.back_as_dispatcher</T></text></li> : userData.portalName && userData.portalUrl &&
            <li><a href={userData.portalUrl}><icon></icon><text><T>{userData.portalName}</T></text></a></li>}</>
        }
        <li className="separator"></li>
        <li command="easter" effect="material" className="disabled" bottom=""><icon>info</icon><text><T>text.version</T></text><separator vertical=""></separator><text><b>{process.env.REACT_APP_VERSION}</b></text><text><count data-count=""></count></text></li>
        <li className="separator"></li>
        <li><a href={logoutUrl}><icon></icon><text><T>text.sign_out</T></text></a></li>
      </ul>
    </div>;

  let selectedItem = null;

  const onColseMaintenanceMessage = () => {
    setCloseMaintenance(false);
  }

const currentDateTimeSecond = Date.parse(new Date())/1000;
const currentDate = Math.floor(currentDateTimeSecond/(60 * 60 * 24));

const dateTimeSecondStart = dataMaintenance?.date_start && Date.parse(moment.utc(dataMaintenance?.date_start).local().format())/1000;
const dateStart = dataMaintenance?.date_start && Math.floor(dateTimeSecondStart/(60 * 60 * 24));
const correctDateStart = dataMaintenance?.date_start && moment.utc(dataMaintenance?.date_start).local().format(Defines.Format.MomentDate);
const correctDateEnd = dataMaintenance?.date_end && moment.utc(dataMaintenance?.date_end).local().format(Defines.Format.MomentDate);
const correctDateTimeStart = dataMaintenance?.date_start && moment.utc(dataMaintenance?.date_start).local().format(Defines.Format.MomentDateTime);
const correctDateTimeEnd = dataMaintenance?.date_end && moment.utc(dataMaintenance?.date_end).local().format(Defines.Format.MomentDateTime);

const getDaysDiff = (currentDate, dateStart) => {
  return dateStart - currentDate;
}

console.log(dataMaintenance);
console.log(currentDate);
console.log(dateStart);
console.log(getDaysDiff(currentDate, dateStart));
console.log(currentDateTimeSecond);
console.log(dateTimeSecondStart);

return <>
  <toolbar id="mainHeader" className="main_header">
    <nav launcher="" id="launcher">
      <Scroll>
        <ul>
          {menuItems.filter(item => item.to != AppPages.ServiceContract || enableServiceContract).map((item, i) => {
            const url = Project.getPageUrl((item.to && Project.hasRoleAccess(role, item.to)) ? item.to : item.subMenu && [...item.subMenu].sort(sm => sm.isDefault ? -1 : 1).find(sub => Project.hasRoleAccess(role, sub.to))?.to) || "";
            
            const selected = (url && url.toLowerCase() === location) || (item.detail && location.startsWith(Project.getPageUrl(item.detail) + "/"))
              || (item.subMenu && item.subMenu.some(sub => location.startsWith(Project.getPageUrl(sub.to)) || (sub.detail && location.startsWith(Project.getPageUrl(sub.detail)))))
              || (item.pages && item.pages.map(p => Project.getPageUrl(p)).indexOf(location) !== -1);
            if (selected) {
              document.onkeydown = (e) => {
                const keyF1 = 'F1';

                if (e.key === keyF1) {
                  if (!item.help.default) {
                    e.preventDefault();
                    const urlPDF = `/help/${(item.help.fr && culture === "fr") ? item.help.fr : item.help.en}`;
                    window.open(urlPDF, "_blank");
                  }

                  return;
                }
              };
              selectedItem = {
                url,
                subMenu: item.subMenu || []
              };
            }

            if (!url) {
              return <div key={i} style={{ display: 'none' }}></div >;
            }

            return <li key={i}
              className={selected ? "selected" : ""} >
              <Link to={url} onClick={closeAllPopups} >
                {(item.render && item.render(unassigned, selected)) || <text>{item.text}</text>}
              </Link>
            </li>;

          })}
        </ul>
      </Scroll>
    </nav>
    <LanguageChangeComponent />

    {
      props.isLoggedIn && !Project.skipHeader(location) && userData &&
      <>
        <separator vertical=""></separator>

        <Dropdown
          className=""
          trigger={['click']}
          overlay={userMenu} placement="topRight">
          <div command="userMenu" effect="material" className="button adaptive">
            <icon data-tip={userData.userName}>user</icon>
            <text>{userData.userName}</text>
          </div>
        </Dropdown>
      </>
    }

  </toolbar>

  { dataMaintenance?.active && closeMaintenance && isLoggedIn && !Project.skipHeader(location) && userData && getDaysDiff(currentDate, dateStart) <= 15 && currentDateTimeSecond <= dateTimeSecondStart && <div className="alert_contianer">
      <alert warning="warning">
        <icon>info</icon>
        {/* <text marquee="marquee"><span><T>text.maintenance_title</T></span></text> */}
        <text marquee="marquee"><span><T args={[`${correctDateTimeStart} - ${correctDateTimeEnd}`]}>text.maintenance_message</T></span></text>
        {/* {culture === 'es' && <text marquee="marquee"><b>{dataMaintenance?.messages?.es && <T>text.maintenance_message</T>}</b></text>}
        {culture === 'pt' && <text marquee="marquee"><b>{dataMaintenance?.messages?.pt && <T>text.maintenance_message</T>}</b></text>}
        {culture === 'en' && <text marquee="marquee"><b>{dataMaintenance?.messages?.en && <T>text.maintenance_message</T>}</b></text>}
        {culture === 'fr' && <text marquee="marquee"><b>{dataMaintenance?.messages?.fr && <T>text.maintenance_message</T>}</b></text>}
        {culture === 'it' && <text marquee="marquee"><b>{dataMaintenance?.messages?.it && <T>text.maintenance_message</T>}</b></text>} */}
        <action>
            {/* <div className="button accent"><text>Verify</text></div>
            <separator vertical="vertical"></separator> */}
            <div className="button mini close" onClick={() => onColseMaintenanceMessage()}><icon>close</icon></div>
        </action>
      </alert>
    </div>
  }
    {selectedItem && selectedItem.subMenu.length && selectedItem.subMenu.some(sub => Project.hasRoleAccess(role, sub.to)) ?
      <toolbar className="main_header">
        <nav launcher="">
          <ul>
            {selectedItem.subMenu.map((item, i) => {
              const url = (Project.hasRoleAccess(role, item.to) && Project.getPageUrl(item.to)) || "";
              return url ? <li key={i} className={url.toLowerCase() === location || (item.detail && location.startsWith(Project.getPageUrl(item.detail) + "/")) ? "selected" : ""}>
                <Link to={url} onClick={closeAllPopups} ><text>{item.text}</text></Link>
              </li> : <div key={i} style={{ display: 'none' }}></div >;
            })}
          </ul>
        </nav>
      </toolbar> : <></>}
  </>;
}
export default connect(state => ({
  role: state.role,
  culture: state.culture,
  userData: state.userData,
  isLoggedIn: state.isLoggedIn,
  enableServiceContract: state.enableServiceContract,
  dataMaintenance: state.dataMaintenance
}),
  dispatch => ({
    dispatch,
    openPopup: (props) => dispatch(openPopup(props)),
    clearListStates: () => dispatch(clearListStates()),
    closeAllPopups: () => dispatch(closeAllPopups()),
    login: (props) => dispatch(login(props))
  }))(withRouter(Menu));