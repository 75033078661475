import React from 'react';
import { connect } from 'react-redux';
import { PopupTypes } from '../../components/Popup';
import { ApiUrl, AppPages } from '../../project/Defines';
import { Project } from '../../project/Project';
import { EVENT_NAME } from '../../project/utilities';
import ListView from '../../Shared/ListView';
import { COLUMN_FORMAT, COLUMN_TYPE } from '../../Shared/ListView/helper';
import { T } from '../../components/Translations';

/**
 * @exports ServiceContract
 */
function ServiceContract(props) {
  //const windowKey = 'wndSocietePicker';
  //const { dispatch, unassigned, role, canCreateCall } = props;

  const columns = [
    {
      text: 'text.contract_id',
      // noSort: 'true',
      field: 'service_contract_id',
      fixed: 'left',
      width: 80,
      type: COLUMN_TYPE.TEXT,
      linkAppPage: AppPages.ServiceContractDetails
    },
    // {
    //   text: 'text.type',
    //   // noSort: 'true',
    //   field: 'contract_type',
    //   fixed: 'left',
    //   width: 80,
    //   render: (text, record) => {
    //     return (
    //       <wrap title={text}>
    //         <text light=''>{text}</text>
    //       </wrap>
    //     );
    //   },
    //   type: COLUMN_TYPE.TEXT
    // },
    {
      text: 'text.status',
      // noSort: 'true',
      //field: 'contract_status',
      field: 'contract_status_translation',
      fixed: 'left',
      width: 100,
      render: (text, record) => {
        return (
          <wrap>
            <group compact="" //NOSONAR
            >
              <badge outline="" //NOSONAR
              >{text}</badge>
            </group>
          </wrap>
        );
      },
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.code_product',
      // noSort: 'true',
      field: 'code_product',
      width: 120,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.product_name',
      // noSort: 'true',
      field: 'item_name',
      width: 160,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.internal_sn',
      field: 'internal_serial_number',
      width: 150,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.subcontractor',
      field: 'subcontractor_name',
      width: 150,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.subcontractor_contract_number',
      // noSort: 'true',
      field: 'subcontractor_contract_number',
      width: 220,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.template_code',
      // noSort: 'true',
      field: 'contract_template',
      width: 130,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.template_description',
      // noSort: 'true',
      field: 'template_description',
      width: 290,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.commission_invoice',
      field: 'commission_invoiced',
      render: (text, record) => {
        return (
          <wrap>
              {text ? <T>text.yes</T> : <T>text.no</T>}
          </wrap>
        );
      },
      width: 160,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.creator',
      // noSort: 'true',
      field: 'creator_user_name',
      width: 140,
      type: COLUMN_TYPE.TEXT
    },
    // {
    //   text: 'text.item_name',
    //   // noSort: 'true',
    //   field: 'item_name',
    //   width: 160,
    //   type: COLUMN_TYPE.TEXT
    // },
    {
      text: 'text.creation_date',
      // noSort: 'true',
      field: 'created_at',
      //render: (text) => <wrap> <text>{moment.utc(text).local().format(Defines.Format.MomentDateTime)}</text></wrap>,
      width: 140,
      format: COLUMN_FORMAT.DATE,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.start_date',
      // noSort: 'true',
      field: 'start_date',
      //render: (text) => <wrap> <text>{moment.utc(text).local().format(Defines.Format.MomentDateTime)}</text></wrap>,
      width: 140,
      format: COLUMN_FORMAT.DATE,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.end_date',
      field: 'end_date',
      width: 140,
      format: COLUMN_FORMAT.DATE,
      type: COLUMN_TYPE.TEXT
    },
    {
      type: COLUMN_TYPE.ACTION,
      fixed: 'right'
    }
  ];

  return (
    <ListView
      {...props}
      //skipNewButton={(!(isDispatcher || isCallCenter) && !canCreateCall) || unassigned}
      className='scroll_fix'
      columnConfig={columns}
      apiUrl={ApiUrl.ServiceContractList}
      detailUrl={Project.getPageUrl(AppPages.ServiceContractDetails)}
      filters={[
        {
          text: '', type: 'group', items: [
            { name: 'service_contract_id', type: 'text', text: 'text.contract_id' },
            { name: 'contract_type', type: 'select', text: 'text.type', valuelist: 'contract_type' },
            { name: 'contract_status', type: 'select', text: 'text.status', valuelist: 'contract_status', disableCach: true },
            { name: 'code', type: 'text', text: 'text.product' },
            { name: 'internal_serial_number', type: 'text', text: 'text.internal_sn' },
            { name: 'contract_template', type: 'select', text: 'text.contract_template', valuelist: 'contract_templates_filter', disableCach: true },
            { name: 'commission_invoiced', type: 'select', text: 'text.commission_invoice', valuelist: 'commission_invoice', disableCach: true },
            { name: 'creator_user_name', type: 'select', text: 'text.creator', valuelist: 'contract_creators', disableCach: true },

            // { name: 'start_date', type: 'text', text: 'text.start_date' }, // to do
            // { name: 'end_date', type: 'text', text: 'text.end_date' } // to do

          ]
        },
        {
          text: 'text.start_date', type: 'group', items: [
            { name: 'start_date', type: 'date', text: 'text.start_date' },
          ]
        },
        {
          text: 'text.end_date', type: 'group', items: [
            { name: 'end_date', type: 'date', text: 'text.end_date' },
          ]
        }
      ]}
      // staticFilter={}
      listUpdateEvent={EVENT_NAME.SERVICE_CONTRACT_LIST_UPDATE}
      listName={AppPages.ServiceContract}
      detailProps={{
        popupType: PopupTypes.ServiceContractDetails,
        windowKey: 'changeServiceContract',
      }}
      exportUrl={ApiUrl.ServiceContractExport}
    />
  );
}


export default connect(state => ({
  role: state.role,
  canCreateCall: state.userData.canCreateCall
}))(ServiceContract);


